import { Button } from 'components/common';
import { SIGNUP_LINK, PLAYSTORE_LINK, APPSTORE_LINK } from 'data';
import AppStore from 'assets/images/imageDownloadAppStore.svg';
import GooglePlay from 'assets/images/imageDownloadGooglePlay.svg';
import BannerBg from 'assets/images/imageBannerBg.svg';

export const Banner = () => {
	return (
		<div className="bg-background overflow-hidden pt-0 pb-[20px] md:pt-[20px] md:pb-[80px]">
			<div className="bg-[#B8F780] w-[90%] xl:w-max-laptop 2xl:w-max-monitor mx-auto p-[24px] md:p-[64px] rounded-[16px] overflow-hidden relative">
				<img
					className="absolute -bottom-[10%] md:bottom-0 -right-[40%] md:-right-[30%] lg:right-0 h-full"
					src={BannerBg}
					alt="Shape"
				/>
				<div className="relative">
					<h2 className="text-[3.6rem] md:text-[4.8rem] font-bold font-calibre-medium mb-[10px]">
						Give it a try today!
					</h2>
					<p className="text-[1.8rem] md:text-[2rem] leading-[25px]">
						Start using Reimbucks now with a risk-free, 14-day free trial.
					</p>
					<div className="flex flex-col md:flex-row justify-between gap-[20px] mt-[32px]">
						<Button link={SIGNUP_LINK} className="w-full h-[46px] md:w-[164px]">
							Start free trial
						</Button>
						<div className="flex gap-[16px] justify-center">
							<a href={APPSTORE_LINK}>
								<img className="h-[46px]" src={AppStore} alt="App Store" />
							</a>
							<a href={PLAYSTORE_LINK}>
								<img className="h-[46px]" src={GooglePlay} alt="Google Play" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
