import { Title, Blur } from 'components/common';
import Feature1Icon from 'assets/images/imageFeatures1Icon.svg';
import Feature1Mockup from 'assets/images/imageFeatures1Mockup.png';
import Feature2Icon from 'assets/images/imageFeatures2Icon.svg';
import Feature2Mockup from 'assets/images/imageFeatures2Mockup.png';
import Feature3Icon from 'assets/images/imageFeatures3Icon.svg';
import Feature3Mockup from 'assets/images/imageFeatures3Mockup.png';

export const Features = () => {
	return (
		<div className="bg-background overflow-hidden pt-[40px] md:pt-[80px] pb-[80px]">
			<Title className="mb-[40px] md:mb-[80px]">Here’s how it works</Title>
			<div className="grid gap-[40px] md:gap-[80px]">
				<div className="flex flex-col-reverse xl:flex-row items-center justify-center mx-auto relative w-[90%] xl:w-[1100px] xl:min-h-[600px]">
					<div className="w-[90%] xl:w-auto flex justify-center items-center xl:items-start xl:flex-col">
						<img
							className="hidden md:block w-[50px] mr-[30px] xl:mr-0"
							src={Feature1Icon}
							alt="One-click Scan & Submit"
						/>
						<div>
							<h2 className="text-[2.8rem] md:text-[4.8rem] font-bold xl:max-w-[280px] my-[5px] md:my-[20px] font-calibre-medium">
								Scan &amp; submit
							</h2>
							<p className="text-[2rem] md:text-[2.4rem] md:max-w-[550px] xl:max-w-[350px]">
								Use your device’s camera to scan any reciept. Reimbucks does the
								rest.
							</p>
						</div>
					</div>
					<div className="relative xl:-mr-[280px]">
						<Blur color="blue" className="w-full h-full inset-0" />
						<img
							className="relative w-[700px]"
							src={Feature1Mockup}
							alt="One-click Scan & Submit"
						/>
					</div>
				</div>

				<div className="flex flex-col xl:flex-row items-center justify-center mx-auto relative w-[90%] xl:w-[1100px] xl:min-h-[600px]">
					<div className="relative xl:-ml-[300px]">
						<Blur color="blue" className="w-full h-full inset-0" />
						<img
							className="relative w-[900px] xl:w-[1000px]"
							src={Feature2Mockup}
							alt="Expense Approval"
						/>
					</div>
					<div className="w-[90%] xl:w-auto flex justify-center items-center xl:items-start xl:flex-col">
						<img
							className="hidden md:block w-[50px] mr-[30px] xl:mr-0"
							src={Feature2Icon}
							alt="Expense Approval"
						/>
						<div>
							<h2 className="text-[2.8rem] md:text-[4.8rem] font-bold my-[5px] md:my-[20px] font-calibre-medium">
								Tap to approve
							</h2>
							<p className="text-[2rem] md:text-[2.4rem] md:max-w-[550px]">
								Review and approve your organization’s expenses and reimburse
								them instantly.
							</p>
						</div>
					</div>
				</div>

				<div className="flex flex-col-reverse xl:flex-row items-center justify-center mx-auto relative w-[90%] xl:w-[1100px] xl:min-h-[600px]">
					<div className="w-[90%] xl:w-auto flex justify-center items-center xl:items-start xl:flex-col">
						<img
							className="hidden md:block w-[50px] mr-[30px] xl:mr-0"
							src={Feature3Icon}
							alt="One-click Scan & Submit"
						/>
						<div>
							<h2 className="text-[2.8rem] md:text-[4.8rem] font-bold my-[5px] md:my-[20px] font-calibre-medium">
								Get a full picture
							</h2>
							<p className="text-[2rem] md:text-[2.4rem] md:max-w-[550px] xl:max-w-[380px]">
								Track expenses and sort them how you want. Never be surprised
								again.
							</p>
						</div>
					</div>
					<div className="relative xl:-mr-[500px]">
						<Blur color="blue" className="w-full h-full inset-0" />
						<img
							className="relative w-[900px]"
							src={Feature3Mockup}
							alt="One-click Scan & Submit"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
