export const ConsistentSpacing = (props) => {
	const { children, className } = props;
	return (
		<div
			className={`px-[clamp(16px,7vw,70px)] py-main-spacing max-w-[900px] mx-auto${
				className ? ` ${className}` : ''
			}`}>
			{children}
		</div>
	);
};
