import { Switch as HeadlessSwitch } from '@headlessui/react';

export const Switch = ({
	checked,
	onChange,
	label,
	className,
	containerClassName,
	circleClassName,
	labelClassName,
	disabled = false,
}) => (
	<HeadlessSwitch.Group
		as="div"
		className={`flex space-x-[5.9px] items-center ${containerClassName ?? ''}`}>
		<HeadlessSwitch
			disabled={disabled}
			checked={checked}
			onChange={onChange}
			className={`${checked ? 'bg-[#7BF3E2]' : 'bg-action-secondary'}
          relative inline-flex flex-shrink-0 h-[19.6px] w-[32px] border border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-0 ${
						className ?? ''
					}`}>
			<span
				aria-hidden="true"
				className={`${checked ? 'translate-x-12' : 'translate-x-1'}
					pointer-events-none inline-block h-[17.6px] w-[17.6px] rounded-full bg-black shadow-lg transform ring-0 transition ease-in-out duration-200 ${
						circleClassName ?? ''
					}`}
			/>
		</HeadlessSwitch>
		{label ? (
			<HeadlessSwitch.Label
				as="p"
				className={`text-[1rem] text-[#858594] tracking-[-0.18px] ${
					labelClassName ?? ''
				}`}>
				{label}
			</HeadlessSwitch.Label>
		) : null}
	</HeadlessSwitch.Group>
);
