import { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { Title, Switch, Button } from 'components/common';
import Save20 from 'assets/images/imagePricingSave20.svg';
import { pricing, SIGNUP_LINK } from 'data';

export const Pricing = () => {
	const [isYearly, setIsYearly] = useState(false);
	return (
		<div className="bg-background overflow-hidden py-[30px] md:py-[60px]">
			<div className="max-w-[90%] mx-auto relative">
				<Title className="mb-[10px]">
					The one expense you <br /> won’t mind paying
				</Title>
				<p className="max-w-[400px] md:max-w-[672px] lg:max-w-[500px] text-[1.8rem] md:text-[2.4rem] leading-[25px] text-center mx-auto">
					Our pricing model is as simple as our app. Pay for what you need for
					as long as you need it.
				</p>

				<div className="flex items-center gap-[20px] mx-auto mb-[30px] mt-[60px] md:my-[60px] w-max relative">
					<button
						onClick={() => setIsYearly(false)}
						className="text-[2rem] font-medium font-calibre-medium">
						Monthly
					</button>
					<Switch
						checked={isYearly}
						onChange={setIsYearly}
						className="w-[64px] h-[32px]"
						circleClassName="w-[29px] h-[29px]"
					/>
					<button
						onClick={() => setIsYearly(true)}
						className="text-[2rem] font-medium font-calibre-medium">
						Yearly
					</button>
					<img
						className="w-[73px] md:w-auto absolute left-[90%] bottom-[100%] md:left-[110%] md:bottom-[10%]"
						src={Save20}
						alt="Save 20%"
					/>
				</div>

				<div className="bg-[#7BF3E2] rounded-[16px] md:rounded-[32px] max-w-[600px] mx-auto p-[20px] md:p-[32px]">
					<h3 className="text-[2.8rem] font-bold font-calibre-medium mb-[16px]">
						{pricing.title}
					</h3>
					<div className="flex items-baseline">
						<h4 className="text-[4.8rem] md:text-[6.4rem] font-bold font-calibre-medium">
							{isYearly ? pricing.cost.yearly : pricing.cost.monthly}
						</h4>
						<p className="text-[2rem]">{pricing.frequency}</p>
					</div>
					<p className="text-[1.8rem] mb-[16px]">{pricing.unit}</p>
					<p className="text-[1.8rem] md:text-[2rem] leading-[25px] mb-[40px]">
						{pricing.description}
					</p>
					<div className="grid gap-[20px]">
						{pricing.featureList.map((feature) => (
							<div className="flex items-start" key={feature}>
								<div>
									<CheckCircleIcon className="w-[20px] h-[20px] mr-[10px]" />
								</div>
								<p className="text-[1.8rem] md:text-[2rem]">{feature}</p>
							</div>
						))}
					</div>
					<Button
						link={SIGNUP_LINK}
						className="mt-[40px] h-[48px] w-full md:w-[164px]">
						Start free trial
					</Button>
				</div>
			</div>
		</div>
	);
};
