import { ConsistentSpacing } from '.';

export const Main = (props) => {
	const { children } = props;
	return (
		<main className="flex-1 py-[30px] text-[#2c3e50]">
			<ConsistentSpacing>{children}</ConsistentSpacing>
		</main>
	);
};

export const ContentContainer = (props) => {
	const { children } = props;
	return <div className="flex flex-col gap-[60px]">{children}</div>;
};

export const RelatedContents = (props) => {
	const { children, className } = props;
	return (
		<div
			className={`flex flex-col gap-[15px]${className ? ` ${className}` : ''}`}>
			{children}
		</div>
	);
};

export const Headline = (props) => {
	const { title, updateOn } = props;

	if (!title && !updateOn) return null;

	return (
		<div className="flex flex-wrap gap-[16px] items-baseline mb-[15px]">
			<h2 className="text-[3.2rem] font-bold">{title}</h2>
			{updateOn && (
				<span className="text-right text-[#a2a2a2] ml-auto">{updateOn}</span>
			)}
		</div>
	);
};

export const Heading3 = (props) => {
	const { children, className, ...rest } = props;

	return (
		<h3
			className={`text-[2.5rem] font-semibold break-all${
				className ? ` ${className}` : ''
			}`}
			{...rest}>
			{children}
		</h3>
	);
};

export const Heading4 = (props) => {
	const { children, ...rest } = props;

	return (
		<h3 className="text-[2.2rem] font-semibold break-all" {...rest}>
			{children}
		</h3>
	);
};

export const SquareListStyle = (props) => {
	const { children, ...rest } = props;

	return (
		<ul
			className="list-square ml-[12px] md:ml-[30px] flex flex-col gap-[15px]"
			{...rest}>
			{children}
		</ul>
	);
};

export const Underline = (props) => {
	const { children, ...rest } = props;

	return (
		<span className="underline" {...rest}>
			{children}
		</span>
	);
};
