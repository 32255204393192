import { useSearchParams } from 'react-router-dom';

const useCheckSource = () => {
	const [searchParams] = useSearchParams();
	const source = searchParams.get('source');
	const isFromApp = source === 'app';

	return { isFromApp, source };
};

export default useCheckSource;
