import Fox from '../assets/images/imageHeroPressFox.svg';
import USAToday from '../assets/images/imageHeroPressUSAToday.svg';
import GoogleNews from '../assets/images/imageHeroPressGoogleNews.svg';
import DigitalJournal from '../assets/images/imageHeroPressDigitalJournal.svg';
import MarketWatch from '../assets/images/imageHeroPressMarketWatch.svg';
import Benzinga from '../assets/images/imageHeroPressBenzinga.svg';

export const press = [
	{
		alt: 'Fox',
		imgSrc: Fox,
	},
	{
		alt: 'USA Today',
		imgSrc: USAToday,
	},
	{
		alt: 'Google News',
		imgSrc: GoogleNews,
	},
	{
		alt: 'Digital Journal',
		imgSrc: DigitalJournal,
	},
	{
		alt: 'Market Watch',
		imgSrc: MarketWatch,
	},
	{
		alt: 'Benzinga',
		imgSrc: Benzinga,
	},
];
