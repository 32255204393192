import { Fragment, useState, useEffect, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-scroll';

import { Button } from 'components/common';
import logo from 'assets/images/imageLogo.svg';
import { navigations, SIGNIN_LINK, SIGNUP_LINK } from 'data';

export const Header = () => {
	const closeBtnRef = useRef(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Popover
			as="header"
			className={`${
				scrollPosition > 200 ? 'fixed top-0 bg-background-fade' : 'relative'
			} w-full z-50`}>
			<div
				className={`${
					scrollPosition > 200 ? 'border-b-0' : 'border-b border-b-separator'
				} flex items-center justify-between py-[18px] md:py-[22px] mx-auto w-[90%] xl:w-max-laptop 2xl:w-max-monitor`}>
				<nav className="flex items-center">
					<a href="/" className="mr-[35px]">
						<img src={logo} alt="Reimbucks Logo" className="max-w-[145px]" />
					</a>
					<div className="hidden md:flex items-center">
						{navigations.map(({ name, to }) => (
							<Link
								key={name}
								to={to}
								className="text-[1.7rem] font-medium cursor-pointer mr-[20px]"
								activeClass="font-bold"
								spy={true}
								smooth={true}
								offset={-80}
								duration={200}>
								{name}
							</Link>
						))}
					</div>
				</nav>
				<nav className="hidden md:flex items-center">
					<Button
						link={SIGNIN_LINK}
						variant="secondary"
						className="font-semibold">
						Sign in
					</Button>
					<Button link={SIGNUP_LINK} className="ml-[12px]">
						Start free trial
					</Button>
				</nav>
				<div className="-mr-2 flex items-center md:hidden">
					<Popover.Button className="p-2 inline-flex items-center justify-center focus:outline-none focus:ring-0">
						<span className="sr-only">Open menu</span>
						<MenuIcon className="w-[30px] h-[30px]" aria-hidden="true" />
					</Popover.Button>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-150 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95">
				<Popover.Panel
					focus
					className="absolute top-0 inset-x-0 transition transform origin-top md:hidden z-50">
					<div className="shadow-md bg-background ring-1 ring-black ring-opacity-5 overflow-hidden">
						<div className="px-[20px] pt-[20px] flex items-center justify-between">
							<img className="h-10 w-auto" src={logo} alt="Reimbucks Logo" />
							<Popover.Button
								ref={closeBtnRef}
								className="inline-flex items-center justify-center focus:outline-none focus:ring-0">
								<span className="sr-only">Close menu</span>
								<XIcon className="w-[25px] h-[25px]" aria-hidden="true" />
							</Popover.Button>
						</div>
						<div className="py-[25px] px-[20px]">
							<div className="px-2 space-y-1 flex flex-col gap-[15px] mb-[25px]">
								{navigations.map(({ name, to }) => (
									<Link
										key={name}
										to={to}
										className="text-[1.7rem] font-medium cursor-pointer"
										activeClass="font-bold"
										spy={true}
										smooth={true}
										offset={-80}
										duration={200}
										onClick={() => closeBtnRef.current.click()}>
										{name}
									</Link>
								))}
							</div>
							<div className="flex flex-col gap-[10px]">
								<Button
									link={SIGNIN_LINK}
									variant="secondary"
									className="font-semibold">
									Sign In
								</Button>
								<Button link={SIGNUP_LINK}>Start Free Trial</Button>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};
