export const Title = ({ children, className }) => {
	return (
		<h1
			className={`text-center text-[3.6rem] md:text-[6.4rem] font-extrabold font-calibre-medium relative ${
				className ?? className
			}`}>
			{children}
		</h1>
	);
};
