export const Blur = ({ className, color = 'green' }) => {
	const colorClasName =
		color === 'green'
			? 'bg-tint-secondary opacity-[0.5] blur-[160px] '
			: 'bg-tint-primary opacity-[0.3] blur-[234px]';
	return (
		<div
			className={`absolute w-[500px] h-[500px] rounded-full ${colorClasName} ${
				className ?? className
			}`}
		/>
	);
};
