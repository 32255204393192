import { Link } from 'react-router-dom';

import Logo from 'assets/images/imageLogo.svg';
import { TERMS_LINK, PRIVACY_LINK } from 'data';

export const Footer = () => {
	return (
		<footer className="bg-background">
			<div className="w-[90%] xl:w-max-laptop 2xl:w-max-monitor flex flex-col gap-[25px] md:flex-row items-center justify-between mx-auto py-[32px] border-t border-t-separator relative">
				<div>
					<img
						className="max-w-[145px] mb-[10px] mx-auto md:mx-0"
						src={Logo}
						alt="Reimbucks"
					/>
					<p className="text-[1.6rem] opacity-[0.56] text-center">
						@{new Date().getFullYear()}, BridgeRock Technologies LLC. All Rights
						Reserved.
					</p>
				</div>
				<div className="text-[1.7rem] font-medium flex flex-col items-center md:flex-row gap-[20px]">
					<Link to="contact">Contact</Link>
					<Link to={TERMS_LINK}>Terms of Service</Link>
					<Link to={PRIVACY_LINK}>Privacy Policy</Link>
				</div>
			</div>
		</footer>
	);
};
