import Feature1 from '../assets/images/imageWhyFeature1.svg';
import Feature2 from '../assets/images/imageWhyFeature2.svg';
import Feature3 from '../assets/images/imageWhyFeature3.svg';

export const features = [
	{
		image: Feature1,
		title: 'Start in minutes',
		content: [
			'Reimbucks is simple enough for anyone in your organization to start using right away.',
			'Just set up an account and add expenses as they come up.',
		],
	},
	{
		image: Feature2,
		title: 'Scan any reciept',
		content: [
			'Get a bird’s eye view of your organization’s spending with quick reciept uploads.',
			'Reimbucks AI automatically pulls data from any reciept.',
		],
	},
	{
		image: Feature3,
		title: 'Track every expense',
		content: [
			'Reimbucks gives you full visibility into every expense your employees make.',
			'Create instant reports with all the information as you need.',
		],
	},
];
