import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';

import { Modal, Input, TextArea, Button } from 'components/common';

export const ContactUs = () => {
	const initialInputFields = {
		name: '',
		email: '',
		message: '',
	};
	const [inputFields, setInputFields] = useState(initialInputFields);
	const navigate = useNavigate();
	const { name, email, message } = inputFields;
	const [disabled, setDisabled] = useState(false);
	const initialResStatus = {
		success: false,
		fail: false,
	};
	const [resStatus, setResStatus] = useState(initialResStatus);
	const { success, fail } = resStatus;

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => (document.body.style.overflow = 'auto');
	}, []);

	const handleCloseModal = () => navigate('/');

	const handleOnChange = ({ target: { name, value } }) => {
		setInputFields((prev) => ({ ...prev, [name]: value }));
	};

	const handleOnFocus = () => {
		(success || fail) && setResStatus(initialResStatus);
	};

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		setResStatus({ fail: false, success: false });
		try {
			setDisabled(true);
			await axios.post('/contact', inputFields);
			setDisabled(false);
			setResStatus({ fail: false, success: true });
			setInputFields(initialInputFields);
		} catch (err) {
			setDisabled(false);
			setResStatus({ fail: true, success: false });
			return err;
		}
	};

	return (
		<Modal
			isOpen={true}
			closeModal={handleCloseModal}
			containerClassName="overflow-hidden max-w-[90%] md:w-[500px]">
			<div className="bg-white p-[24px]">
				<h2 className="text-[2.4rem] font-[700] font-calibre-medium text-center">
					Contact Us
				</h2>
				{(success || fail) && (
					<div
						className={`flex items-center justify-center gap-[8px] mt-[12px] ${
							success ? 'text-brand' : fail ? 'text-red-400' : ''
						}`}>
						{success && <CheckCircleIcon className="w-[20px] h-[20px]" />}
						<p className="text-[1.6rem]">
							{success &&
								'Thank you for contacting us. We will respond as soon as possible.'}
							{fail && 'Something went wrong. Please try again.'}
						</p>
					</div>
				)}
			</div>
			<form
				onSubmit={handleOnSubmit}
				className="bg-[#FAFAFC] p-[24px] border-t border-separator flex flex-col gap-[12px]">
				<Input
					label="Name "
					labelClassName="after:content-['*'] after:text-red-400"
					placeholder="Your name"
					name="name"
					value={name}
					onChange={handleOnChange}
					onFocus={handleOnFocus}
					disabled={disabled}
					required
				/>
				<Input
					label="Email "
					labelClassName="after:content-['*'] after:text-red-400"
					placeholder="Your email"
					name="email"
					value={email}
					onChange={handleOnChange}
					onFocus={handleOnFocus}
					disabled={disabled}
					required
					type="email"
				/>
				<TextArea
					label="Message "
					labelClassName="after:content-['*'] after:text-red-400"
					placeholder="Your message"
					name="message"
					value={message}
					onChange={handleOnChange}
					onFocus={handleOnFocus}
					rows={8}
					disabled={disabled}
					required
				/>
				<div className="grid grid-cols-2 gap-[12px] mt-[12px]">
					<Button
						type="button"
						variant="secondary"
						onClick={handleCloseModal}
						disabled={disabled}>
						Cancel
					</Button>
					<Button type="submit" disabled={disabled}>
						Send message
					</Button>
				</div>
			</form>
		</Modal>
	);
};
