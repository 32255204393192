import logo from 'assets/images/imageLogo.svg';
import useCheckSource from 'hooks/useCheckSource';

import { ConsistentSpacing } from '.';

export const Header = () => {
	const { isFromApp } = useCheckSource();
	return (
		<header className="border-b border-[#EDEDF2] sticky top-0 bg-[#FFFFFF]">
			<ConsistentSpacing className="flex items-center flex-wrap gap-[16px] p-[16px]">
				{isFromApp ? (
					<img src={logo} alt="Reimbucks Logo" className="max-w-[145px]" />
				) : (
					<a href="/">
						<img src={logo} alt="Reimbucks Logo" className="max-w-[145px]" />
					</a>
				)}
			</ConsistentSpacing>
		</header>
	);
};
