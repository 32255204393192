import { Blur, Title } from 'components/common';
import WhyImage from 'assets/images/imageWhy.png';
import { features } from 'data';

export const WhyReimbucks = () => {
	return (
		<div className="bg-background overflow-hidden">
			<div className="py-[80px] w-[90%] xl:w-max-laptop 2xl:w-max-monitor mx-auto relative">
				<Blur className="hidden md:block -top-[200px] -left-[450px] lg:-left-[300px] w-[500px] h-[500px]" />
				<Blur className="-top-[200px] -right-[700px] lg:-right-[400px] w-[800px] h-[800px]" />
				<Blur className="top-[200px] -right-[-700px] md:-right-[500px] lg:-right-[200px] w-[800px] h-[800px] rounded-none" />
				<Blur className="bottom-[100px] lg:-bottom-[400px] -left-[700px] lg:-left-[400px] w-[800px] h-[800px] rounded-none" />
				<div className="relative">
					<Title className="mb-[50px]">Why Reimbucks?</Title>
					<img className="w-full" src={WhyImage} alt="Reimbucks Features" />
					<div className="flex flex-col gap-[16px] xl:gap-0 xl:flex-row justify-between items-start -mt-[40px] md:-mt-[100px] xl:-mt-[170px] w-[95%] mx-auto">
						{features.map(({ image, title, content }) => (
							<div
								key={title}
								className="bg-white shadow-xl rounded-[12px] p-[30px] w-full xl:w-[32%]">
								<img className="w-[45px] h-[45px]" src={image} alt={title} />
								<h3 className="text-[2.8rem] md:text-[3rem] font-bold font-calibre-medium my-[20px]">
									{title}
								</h3>
								<div className="flex flex-col gap-[15px]">
									{content.map((item) => (
										<p key={item} className="text-[1.8rem] md:text-[2rem]">
											{item}
										</p>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
