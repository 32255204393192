import { Link } from 'react-router-dom';

import {
	ContentContainer,
	Footer,
	Header,
	Heading3,
	Headline,
	Main,
	RelatedContents,
} from 'components/termsAndPrivacy';
import { PRIVACY_LINK } from 'data';
import useCheckSource from 'hooks/useCheckSource';

const LinkToPrivacy = (
	<Link
		to={PRIVACY_LINK}
		target="_blank"
		rel="noreferrer"
		className="text-brand whitespace-nowrap">
		Privacy Policy
	</Link>
);

export const TermsOfService = () => {
	const { isFromApp } = useCheckSource();
	return (
		<>
			<Header />

			<Main>
				<Headline
					title="TERMS OF SERVICES"
					updateOn="Last updated Sep 1, 2022"
				/>

				<ContentContainer>
					<RelatedContents>
						<p>
							Bridgerock Technologies LLC (“<strong>Bridgerock</strong>”, “
							<strong>we</strong>”, “<strong>us</strong>”) provides (1) the
							Reimbucks’ website located at www.reimbucks.com and/or the
							Reimbucks’ mobile application and all associated Reimbucks’ sites
							linked to such website and/or mobile application (collectively,
							the “<strong>Sites</strong>”) and (2) services accessible through
							the Sites for Users to use and access Reimbucks’ services
							(collectively, the “<strong>Services</strong>”).
						</p>

						<p>
							These Terms of Service (“Terms” or “Agreement”) govern your access
							to, and use of, the Services. Please read these Terms carefully.
							They require the use of mandatory binding arbitration to resolve
							disputes rather than jury trials or group litigation. Please
							follow the instructions in the Dispute Resolution and Arbitration
							Section (Section 8) below if you wish to opt out of this
							provision. The term “you” and “User”, as used in these Terms,
							means any individual or legal entity that uses or accesses the
							Services. The term “Submitting User”, as used in these Terms,
							means any User that uses or accesses the Services to submit
							reimbursement requests to an Admin User for reimbursement outside
							of the Services. The term “Admin User”, as used in these Terms,
							means any User that uses or accesses the Services to receive,
							review, and if approved, reimburse Submitting User outside of the
							Services. These Terms give you specific legal rights. In addition,
							you may also have other legal rights which vary from jurisdiction
							to jurisdiction. The disclaimers, exclusions, mandatory and
							binding arbitration, limitations of liability, indemnification,
							waiver of jury trial, waiver of class action and waiver of
							punitive damages under these Terms will not apply to the extent
							prohibited by applicable law. Some jurisdictions do not allow the
							exclusion of implied warranties or the exclusion or limitation of
							incidental or consequential damages or other rights, so those
							provisions of these Terms may not apply to you.
						</p>

						<p>
							THESE TERMS ARE A LEGAL AGREEMENT. BY ACCEPTING THESE TERMS BY
							ACCESSING THE WEBSITE AND USING THE SERVICES, YOU ARE ACCEPTING
							AND AGREEING TO THESE TERMS ON BEHALF OF YOURSELF AND/OR THE
							ENTITY THAT YOU REPRESENT IN CONNECTION WITH THE ACCESS AND USE.
							YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY AND
							CAPACITY TO ACCEPT AND AGREE TO THESE TERMS ON BEHALF OF YOURSELF
							AND/OR THE ENTITY THAT YOU REPRESENT. YOU REPRESENT THAT YOU ARE
							OF SUFFICIENT LEGAL AGE IN YOUR JURISDICTION OR RESIDENCE TO USE
							AND ACCESS THE SERVICES AND TO ENTER INTO THIS AGREEMENT. IF YOU
							DO NOT AGREE WITH ANY OF THE PROVISIONS OF THESE TERMS, YOU MUST
							DELETE YOUR ACCOUNT AND CEASE ACCESSING AND USING THE SERVICES.
						</p>

						<p>
							AS DESCRIBED BELOW, SECTIONS 3, 4 AND 5 DESCRIBE IMPORTANT
							LIMITATIONS OF THE SERVICES. PLEASE READ THESE DISCLOSURES
							CAREFULLY, AS YOU ARE ACKNOWLEDGING THEM AND ACCEPTING THEM.
						</p>

						<p>
							SECTION 8 "DISPUTE RESOLUTION AND ARBITRATION/CLASS ACTION
							WAIVER/JURY TRIAL WAIVER/WAIVER OF PUNITIVE DAMAGES" CONTAINS A
							BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT AFFECT
							YOUR LEGAL RIGHTS. If you are a User in the European Economic Area
							(“EEA”), or any other country that does not allow such arbitration
							agreement, Section 12 does not apply to you.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>1. Overview, Eligibility, Term and Termination</Heading3>
						<p>
							(a) Overview and Relation to Other Agreements. These Terms govern
							your use of the Sites and Services. Certain features of the
							Services may be subject to additional guidelines, terms or rules,
							which will be posted on the Sites in connection with such
							features. All additional guidelines, terms or rules are
							incorporated by reference into these Terms and you are agreeing to
							accept and abide by them by using the Services.
						</p>

						<p>
							(b) Eligibility. You may use the Services only if you have the
							legal capacity to form a binding contract with Bridgerock; you
							accept these Terms through the Sites, or by accessing or using the
							Services; and only if you are in compliance with these Terms and
							all applicable local, state/provincial, national and international
							laws, rules and regulations. Only individuals aged 18 and older
							are permitted to create an Account.
						</p>

						<p>
							(c) Account Creation. To participate in certain features of the
							Services, you are required to create an account and provide
							certain information about yourself. You agree that any information
							furnished to Bridgerock in connection with your account is true,
							accurate, complete, up-to-date, and solely yours. You may not
							impersonate, imitate, or pretend to be someone else when creating
							an account. If any of your information changes, you must promptly
							update it. Bridgerock shall have no liability associated with or
							arising from your failure to maintain accurate, complete, or
							up-to-date information within your account. Bridgerock reserves
							the right to suspend or terminate your account and access to the
							Services for any reason.
						</p>

						<p>
							(d) Term and Termination. These Terms will remain in full force
							and effect for a month or a year, depending on the corresponding
							period of time for which you have subscribed to the Services
							(“Term”). Each Term shall be automatically renewed for subsequent
							equal Terms (“Renewal Term”) until you terminate your account, or
							Bridgerock terminates your account or access to the Services in
							accordance with these Terms. At any time, Bridgerock may, at our
							sole discretion, (i) suspend or terminate your rights to access
							the Sites and/or use the Services, or (ii) terminate these Terms
							with respect to you if Bridgerock in good faith believes that you
							have used or accessed the Services in violation of these Terms,
							including any incorporated guidelines, terms or rules.
						</p>

						<p>
							(e) Effect of Termination. Upon termination of these Terms, your
							right to use the Services will automatically terminate. User
							agrees that Bridgerock will not have any liability whatsoever to
							you for any termination of your rights under these Terms,
							including for termination of your access to our Services or your
							User Content (including pending reimbursement requests, and
							associated User Content).
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>2. Access to Services</Heading3>
						<p>
							(a) User Fees. The amount of fees depends on the type of
							subscription you sign up for (i.e., monthly or annual
							subscription) and will be provided to you when you create your
							Reimbucks account. Any of the foregoing may be revised at any
							time, and you will be deemed to have agreed to such revisions by
							your continued use of the Services. Any fees that you incur based
							on your subscription level (monthly or annual subscription) will
							be billed by Bridgerock to you on an annual or monthly basis to
							correspond with your subscription.
						</p>

						<p>
							(b) Access and Use. Subject to these Terms, Bridgerock grants you
							a non-transferable, non-exclusive right (without the right to
							sub-license) to access and use the Services by (i) using the Site,
							(ii) in good faith approving Bridgerock Users regarding the access
							the Services and Site as explicitly permitted by these Terms (the
							“Permitted Purposes”). Any User’s use of the Services in violation
							of or contrary to the Permitted Purposes is subject to termination
							of User’s Access.
						</p>

						<p>
							(c) Certain Restrictions. The rights granted to you in these Terms
							are subject to the following restrictions: (i) you agree not to
							license, sell, rent, lease, transfer, assign, distribute, host or
							otherwise commercially exploit the Services; (ii) you agree not to
							modify, make derivative works of, disassemble, reverse-compile or
							reverse-engineer any part of the Services; (iii) except as
							expressly stated herein, no part of the Services may be copied,
							reproduced, distributed, republished, downloaded, displayed,
							posted or transmitted in any form or by any means; (iv) you agree
							not to upload, transmit or distribute any computer viruses, worms
							or any software intended to damage or alter a computer or
							communications network, computer, handheld mobile device, data,
							the Services, or any other system, device or property; (v) you
							agree not to interfere with, disrupt or attempt to gain
							unauthorized access to the servers or networks connected to the
							Services or violate the regulations, policies or procedures of
							such networks; (vi) you agree not to access (or attempt to access)
							any of the Services by means other than through the interface that
							is provided by Bridgerock; and (vii) you agree not to remove,
							obscure or alter any proprietary rights notices (including
							copyrights and trademark notices) that may be contained in, or
							displayed in connection with, the Services. Any future release,
							update or other addition to functionality of the Services shall be
							subject to these Terms.
						</p>

						<p>
							(d) Privacy. Please review below, and the Privacy Policy for
							Bridgerock Services. These documents describe practices regarding
							the information that you or Bridgerock may collect from users of
							the Services.
						</p>

						<p>
							(e) Security. We endeavor to implement appropriate security
							measures. However, Bridgerock cannot guarantee that unauthorized
							third parties will never be able to defeat our security measures
							or use your personal information for improper purposes. You
							acknowledge that you provide your personal information at your own
							risk.
						</p>

						<p>
							(f) Modification. Bridgerock reserves the right, at any time, to
							modify, suspend or discontinue the Services or any part thereof
							with or without notice to you at our sole discretion. You agree
							that Bridgerock will not be liable to you or to any third party
							for any modification, suspension, or discontinuance of the
							Services or any part thereof.
						</p>

						<p>
							(g) Mobile Application. You acknowledge and agree that
							availability of the mobile application is dependent on the
							third-party platform (the “App Store”) from which you downloaded
							the Services. You acknowledge that these Terms are between you and
							Bridgerock and not with an App Store. Each App Store may have its
							own terms and conditions to which you must agree before
							downloading the Reimbucks mobile application from it. You agree to
							comply with such App Store terms and conditions, and your license
							to use the mobile application is conditioned upon your compliance
							with such App Store terms and conditions. To the extent that such
							other terms and conditions from such App Store are less
							restrictive than or otherwise conflict with the terms and
							conditions of these Terms, the more restrictive or conflicting
							terms and conditions in these Terms apply.
						</p>

						<p>
							(h) Push Notifications. By accepting these Terms, you agree to
							receive push notifications to your mobile phone through the
							Reimbucks mobile application. However, you can opt out of
							receiving future push notifications by updating your preferences
							within your account or in your phone settings.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>3. Agreed Usage and Limitations of Services </Heading3>
						<p>
							(a) Intended Use of Services. The Services are intended to be
							accessed and used for non-time-critical purposes. While we aim for
							the Services to be highly reliable and available, they are not
							intended to be reliable or available 100% of the time. The
							Services are subject to sporadic interruptions and failures for a
							variety of reasons beyond Bridgerock’s control. You acknowledge
							these limitations and agree that Bridgerock is not responsible for
							any damages allegedly caused by the failure or delay of the
							Services.
						</p>
						<p>
							(b) Reliability of Services. You acknowledge that the Services are
							not error-free, 100% reliable, or 100% available. Proper
							functioning of the Services relies and is dependent on, among
							other things, the transmission of data through Wi-Fi networks,
							enabled wireless device (such as a phone or tablet) and broadband
							internet access, or cellular service, for which neither Bridgerock
							nor any internet, wireless or data carrier is responsible, and may
							be interrupted, delayed, refused, or otherwise limited for a
							variety of reasons, including insufficient coverage, power
							outages, termination of service and access, environmental
							conditions, interference, non-payment of applicable fees and
							charges, system capacity, upgrades, repairs or relocations, and
							priority access by emergency responders in the event of a disaster
							or emergency (collectively, “Service Interruptions”). You
							understand that Service Interruptions may result in the Services
							being unreliable or unavailable for the duration of the Service
							Interruption.
						</p>
						<p>
							(c) Service Interruptions. The Services may be suspended
							temporarily, without notice, for security reasons, systems
							failure, maintenance and repair, or other circumstances.
							Bridgerock does not offer any specific uptime guarantee for the
							Services.
						</p>
						<p>
							(d) Outages. In the event of any Service Interruptions to the
							Wi-Fi network or internet connection to which you are utilizing to
							use or access the Services, the Services may be unreliable or
							unavailable for the duration of the Service Interruption. Further,
							features and functionalities of the Services may be unavailable to
							you for the duration of the Service Interruption.
						</p>
						<p>
							(e) User Content and Acceptable Use. You warrant, represent and
							agree that you will not contribute any Content (defined as any
							information, data, images, documentation, or anything else
							uploaded to the Services) or otherwise use the Services in a
							manner that (i) infringes or violates the intellectual property
							rights or proprietary rights, rights of publicity or privacy or
							other rights of any third party; (ii) violates any law, statute,
							ordinance or regulation or is otherwise illegal; (iii) is harmful,
							fraudulent, deceptive, threatening, abusive, harassing, tortious,
							defamatory, vulgar, obscene, libelous or otherwise objectionable;
							(iv) impersonates any person or entity, including, without
							limitation, any employee or representative of Bridgerock; (v)
							contains a virus, Trojan Horse, worm, time bomb or other harmful
							computer code, file or program; (vi) attempts, in any manner, to
							obtain or access the password, account, devices, systems, or other
							security information from any other user or third party; (vii)
							violates the security of any computer network or cracks any
							passwords or security encryption codes; (viii) runs maillist,
							listserv or any form of auto-responder or “spam” on the Services
							or any processes that otherwise interfere with the proper working
							of the Services (including by placing an unreasonable load on the
							Services’ infrastructure); (ix) decompiles, reverse-engineers or
							otherwise attempts to obtain the source code or underlying ideas
							or information of or relating to the Services; or (x) denigrates
							or disrupts any network capacity or functionality.
						</p>
						<p>
							(f) Requesting Reimbursement. Submitting User may request
							reimbursement from an Admin User through the Services. Submitting
							User understands and acknowledges that Admin Users to whom
							Submitting User sends reimbursement requests may reject or ignore
							Submitting User’s request. Bridgerock does not guarantee that
							Submitting User will receive money from Admin User by sending a
							reimbursement request, or that Submitting User will receive the
							amount that Submitting User requested. Bridgerock will have no
							liability or responsibility if an Admin User ignores or rejects
							Submitting User’s request or sends Submitting User an amount that
							is less than what Submitting User request. Admin User agrees to
							receive reimbursement requests from Submitting Users, and
							Submitting User agrees to only send requests for legitimate and
							lawful purposes. Admin User and Submitting User agree and
							understand that payment of such reimbursement request cannot be
							made through the Services. Bridgerock assumes no responsibility
							for the accuracy or legality of any reimbursement requests. You
							further agree that you are not engaging in the business of debt
							collection by attempting to use the Service to request
							reimbursement for the payment or collection of an overdue or
							delinquent debt; to request money that is owed to anyone but
							yourself; or to collect any amounts that are owed pursuant to a
							court order. Bridgerock reserves the right, but assumes no
							obligation, to terminate your ability to send reimbursement
							requests in general, or to specific recipients, if Bridgerock
							deems such requests to be potentially unlawful, abusive,
							offensive, or unwelcome by the recipient.
						</p>
						<p>
							(g) No Liability for Incomplete Reimbursement Request and
							Subsequent Reimbursement. If you enter into a reimbursement
							transaction with another User and have a dispute over the
							reimbursement paid, Bridgerock will have no liability for the
							underlying reimbursement transaction. Bridgerock’s only
							responsibility is to handle the reimbursement request, and not the
							subsequent payment of the reimbursement. In addition, Brigerock
							will not be liable for its inability to process a reimbursement
							request in the following instances: (i) the information supplied
							by you was incorrect, incomplete, ambiguous, or untimely; (ii) the
							picture of the receipt Submitting User submitted for reimbursement
							is blurry or unable to be read for any reason; (iii) the Admin
							User declines your payment; (iv) we have reason to believe the
							request is unauthorized, fraudulent, or violates these Terms; or
							(v) any other exception stated in these Terms.
						</p>
						<p>
							(h) Privacy. For more information on how we process personal
							information when you interact with or use our Services, see the{' '}
							{isFromApp ? 'Privacy Policy' : LinkToPrivacy}. You agree that you
							are responsible for ensuring that you comply with any applicable
							laws when you use the Services.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>4. INDEMNITY FOR THIRD PARTY ACTIONS</Heading3>
						<p>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO
							INDEMNIFY, DEFEND, RELEASE AND HOLD BRIDGEROCK AND ITS LICENSORS
							AND SUPPLIERS (COLLECTIVELY, THE “BRIDGEROCK PARTIES”) HARMLESS
							FROM AND AGAINST (I) ALL CLAIMS, ACTIONS, LAWSUITS AND ANY OTHER
							LEGAL ACTION BROUGHT BY ANY THIRD PARTY AGAINST ANY OF THE
							BRIGEROCK PARTIES ARISING FROM OR RELATING TO (A) YOUR USE OF THE
							SERVICES; (B) YOUR VIOLATION OF THESE TERMS; OR (C) YOUR VIOLATION
							OF ANY LAW OR THE RIGHTS OF ANY THIRD-PARTY, INCLUDING BUT NOT
							LIMITED TO, INTELLECTUAL PROPERTY RIGHTS (COLLECTIVELY, “THIRD
							PARTY ACTIONS”); AND (I) ANY AND ALL RELATED LOSSES, DAMAGES,
							SETTLEMENTS AND JUDGEMENTS (INCLUDING PAYMENT OF THE BRIDGEROCK
							PARTIES’ ATTORNEYS’ FEES AND COSTS) INCURRED BY ANY OF THE
							BRIDGEROCK PARTIES, ASSESSED OR FOUND AGAINST ANY OF THE
							BRIDGEROCK PARTIES, OR MADE BY ANY OF THE BRIDGEROCK PARTIES,
							RELATING TO OR ARISING FROM ANY SUCH THIRD PARTY ACTION (“THIRD
							PARTY RELATED LOSSES”). YOU UNDERSTAND AND AGREE THAT YOUR
							INDEMNIFICATION OBLIGATION TO THE BRIDGEROCK PARTIES APPLIES EVEN
							IF SUCH THIRD-PARTY ACTION AND THIRD PARTY RELATED LOSSES ARISE
							FROM THE NEGLIGENCE OF ANY KIND OR DEGREE, BREACH OF CONTRACT OR
							WARRANTY, STRICT LIABILITY, NON-COMPLIANCE WITH APPLICABLE LAW, OR
							OTHER FAULT OR WRONGDOING OF ANY OF THE BRIDGEROCK PARTIES.
							HOWEVER, NOTHING CONTAINED HEREIN SHALL BE CONSTRUED TO REQUIRE
							ANY INDEMNIFICATION WHICH WOULD RENDER OR MAKE THIS CLAUSE, IN
							WHOLE OR IN PART, VOID AND/OR UNENFORCEABLE UNDER APPLICABLE LAW.
							FURTHER, YOUR INDEMNIFICATION OBLIGATION SHALL NOT APPLY TO ANY
							WILLFUL, WANTON, INTENTIONAL OR RECKLESS MISCONDUCT OF THE
							BRIDGEROCK PARTIES, OR GROSS NEGLIGENCE OF THE BRIDGEROCK PARTIES
							IN THOSE STATES THAT DO NOT PERMIT INDEMNIFICATION FOR GROSS
							NEGLIGENCE. “THIRD PARTY” IS DEFINED HEREIN TO INCLUDE, BUT IS NOT
							LIMITED TO, A USER’S EMPLOYEE, USER’S CLIENT OR CUSTOMER,
							EMPLOYEE, OR INSURANCE COMPANY. BRIDGEROCK RESERVES THE RIGHT, AT
							YOUR EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY
							MATTER FOR WHICH YOU ARE REQUIRED TO INDEMNIFY BRIDGEROCK, AND YOU
							AGREE TO COOPERATE WITH OUR DEFENSE OF SUCH CLAIMS. YOU AGREE NOT
							TO SETTLE ANY SUCH CLAIM WITHOUT BRIDGEROCK’S PRIOR WRITTEN
							CONSENT. BRIDGEROCK WILL USE REASONABLE EFFORTS TO NOTIFY YOU OF
							ANY SUCH CLAIM, ACTION, OR PROCEEDING UPON BECOMING AWARE.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>5. Warranty Disclaimers</Heading3>
						<p>
							(a) THE SERVICES ARE PROVIDED FOR YOUR CONVENIENCE, “AS IS” AND
							“AS AVAILABLE”, AND BRIDGEROCK, AND OUR LICENSORS AND SUPPLIERS,
							EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND,
							WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS
							OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY AND
							NON-INFRINGEMENT.
						</p>
						<p>
							(b) BRIDGEROCK AND OUR LICENSORS AND SUPPLIERS, MAKE NO WARRANTY
							THAT DEFECTS WILL BE CORRECTED OR THAT THE SERVICES: (I) WILL MEET
							YOUR REQUIREMENTS; (II) WILL BE COMPATIBLE WITH ANY NETWORK, OR
							YOUR COMPUTER OR MOBILE DEVICE; (III) WILL BE AVAILABLE ON AN
							UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE BASIS; OR (IV) WILL BE
							ACCURATE OR RELIABLE. NO ADVICE OR INFORMATION, WHETHER ORAL OR
							WRITTEN, OBTAINED BY YOU FROM BRIDGEROCK OR THOUGH THE SERVICES,
							SHALL CREATE ANY WARRANTY.
						</p>
						<p>
							(c) BRIDGEROCK DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME
							RESPONSIBILITY FOR ANY SERVICE ADVERTISED OR OFFERED BY A THIRD
							PARTY THROUGH, OR IN CONNECTION WITH, THE SERVICES, OR ANY
							HYPERLINKED WEBSITE OR SERVICE, AND BRIDGEROCK WILL NOT BE A PARTY
							TO, OR IN ANY WAY MONITOR, ANY TRANSACTION BETWEEN YOU AND
							THIRD-PARTY PROVIDERS OF SUCH PRODUCTS OR SERVICES.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>6. Limitation of Liability</Heading3>
						<p>
							Nothing in these Terms and, in particular, within this “Limitation
							of Liability” clause, shall be interpreted or construed to limit
							or exclude liability that cannot be so limited or excluded under
							applicable law.
						</p>
						<p>
							TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN ADDITION TO
							THE WARRANTY AND OTHER DISCLAIMERS IN THESE TERMS, IN NO EVENT
							WILL (A) BRIDGEROCK BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
							EXEMPLARY, SPECIAL OR INCIDENTAL DAMAGES, INCLUDING, WITHOUT
							LIMITATION, ANY DAMAGES FOR LOST DATA, OR LOST PROFITS ARISING
							FROM OR RELATING TO THE SERVICES, EVEN IF BRIDGEROCK KNEW, OR
							SHOULD HAVE KNOWN, OF THE POSSIBILITY OF SUCH DAMAGES, AND (B)
							BRIDGEROCK’S TOTAL CUMULATIVE LIABILITY FOR ANY DIRECT DAMAGES,
							PROPERTY DAMAGE, OR ANY OTHER DAMAGES NOT EXCLUDED OR PRECLUDED
							PURSUANT TO (A) ABOVE, ARISING FROM OR RELATED TO THE SERVICES,
							WHETHER IN CONTRACT OR TORT OR OTHERWISE, SHALL BE LIMITED TO AN
							AMOUNT NEVER TO EXCEED THE FEES ACTUALLY PAID BY YOU TO BRIDGEROCK
							FOR THE SERVICES AT ISSUE IN THE PRIOR 12 MONTHS. THE EXISTENCE OF
							MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. BRIDGEROCK
							DISCLAIMS ALL LIABILITY OF ANY KIND OF BRIDGEROCK’S LICENSORS AND
							SUPPLIERS. UNDER NO CIRCUMSTANCES WILL BRIDGEROCK BE LIABLE IN ANY
							WAY FOR ANY CONTENT, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR
							OMISSIONS IN ANY CONTENT OR ANY LOSS OR DAMAGE OF ANY KIND
							INCURRED IN CONNECTION WITH USE OF, OR EXPOSURE TO, ANY CONTENT
							POSTED, EMAILED, ACCESSED, TRANSMITTED OR OTHERWISE MADE AVAILABLE
							VIA THE SERVICES.
						</p>
						<p>
							YOU UNDERSTAND AND AGREE THAT THIS LIMITATION OF LIABILITY IN THIS
							SECTION 7 SHALL APPLY EVEN IF BRIDGEROCK IS FOUND LIABLE FOR ANY
							LOSS OR DAMAGE DUE TO BREACH OF CONTRACT, BREACH OF EXPRESS OR
							IMPLIED OR WARRANTY, NEGLIGENCE OF ANY KIND OR DEGREE, STRICT
							PRODUCT LIABILITY, SUBROGATION, INDEMNIFICATION OR CONTRIBUTION,
							OR ANY OTHER THEORY OF LIABILITY. HOWEVER, THIS LIMITATION OF
							LIABILITY SHALL NOT APPLY TO ANY WILLFUL, WANTON, INTENTIONAL OR
							RECKLESS MISCONDUCT OF BRIDGEROCK OR GROSS NEGLIGENCE OF
							BRIDGEROCK IN THOSE STATES THAT DO NOT PERMIT LIMITATION OF
							LIABILITY FOR GROSS NEGLIGENCE.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>
							7. DISPUTE RESOLUTION AND ARBITRATION/CLASS ACTION WAIVER/JURY
							TRIAL WAIVER/WAIVER OF PUNITIVE DAMAGES
						</Heading3>
						<p>
							PLEASE READ THIS SECTION CAREFULLY. FOLLOW THE INSTRUCTIONS BELOW
							IF YOU WISH TO OPT OUT OF THE REQUIREMENT OF ARBITRATION ON AN
							INDIVIDUAL BASIS.
						</p>
						<p>
							(a) Arbitration. Bridgerock and you agree to arbitrate all
							disputes and claims that arise from or relate to these Terms or
							the Services in any way, except for claims arising from bodily
							injury. THIS ARBITRATION IS MANDATORY AND NOT PERMISSIVE. This
							agreement to arbitrate is intended to be broadly interpreted,
							including, for example:
						</p>
						<ul className=" list-disc flex flex-col gap-[10px]">
							<li>
								claims arising out of or relating to any aspect of the
								relationship between us that is created by or involves these
								Terms or the Services, regardless of the legal theory;
							</li>
							<li>
								claims for mental or emotional distress or other
								emotional/mental injury arising from the relationship between
								us;
							</li>
							<li>
								claims that arose before you accepted these Terms (such as
								claims related to disclosures or the marketing of the Services,
								or the process for seeking approval to use the Services);
							</li>
							<li>
								claims that may arise after the termination of your use of the
								Services or any agreement between us; and
							</li>
							<li>
								claims brought by or against our parent companies, members and
								affiliates, as well as the respective officers, directors,
								employees, agents, predecessors, successors and assigns of these
								entities, you, and Bridgerock.
							</li>
						</ul>
						<p>
							This arbitration agreement does not preclude either of us from
							bringing an individualized action in small claims court. It also
							does not preclude either of us from seeking an individualized
							preliminary injunction or temporary restraining order, pending
							arbitration, in any court that has jurisdiction. Nor does this
							arbitration agreement bar you from bringing issues to the
							attention of federal, state or local agencies. Such agencies can,
							if the law allows, seek relief against us on your behalf. In
							addition, you or Bridgerock may seek injunctive or other equitable
							relief to protect your or its trade secrets and intellectual
							property rights or to prevent loss or damage to its services in
							any court with competent jurisdiction.
						</p>
						<p>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE, LAW, YOU UNDERSTAND
							AND AGREE THAT WE ARE EACH (A) WAIVING THE RIGHT TO A TRIAL BY
							JURY; (B) WAIVING THE RIGHT TO PARTICIPATE IN A CLASS OR
							REPRESENTATIVE ACTION; AND (C) WAIVING THE RIGHT TO CLAIM OR
							RECOVER PUNITIVE DAMAGES AGAINST THE OTHER. These Terms evidence a
							transaction in interstate commerce, and thus the Federal
							Arbitration Act governs the interpretation and enforcement of this
							arbitration provision.
						</p>

						<p>
							(a) Notice of Disputes. If either of us intends to seek
							arbitration of a dispute, that party must provide the other with
							notice in writing. The notice to Bridgerock should be sent to
							support@reimbucks.com. Bridgerock will send notice to you at the
							email and/or mailing addresses associated with your account. Your
							notice to Bridgerock must (a) provide your name, mailing address,
							and email address; (b) describe the dispute; and (c) state the
							relief you are requesting. If we are unable to reach an agreement
							to resolve the dispute within 60 days after the notice is
							received, you or we may commence arbitration.
						</p>

						<p>
							(b) Arbitration Procedures. The arbitration will be governed by
							the Consumer Arbitration Rules (“AAA Rules”) of the American
							Arbitration Association (“AAA”), as modified by these Terms, and
							will be administered by the AAA. The AAA Rules are available
							online at www.adr.org or by calling the AAA at 1-800-778-7879. If
							the AAA is unavailable, the parties shall agree to another
							arbitration provider or the court shall appoint a substitute.
							Unless you and we agree otherwise, any arbitration hearings will
							take place in the county (or parish) where you are receiving
							Services. If the value of your claim is USD $10,000 or less, we
							agree that you may choose whether the arbitration will be
							conducted solely on the basis of documents submitted to the
							arbitrator, by telephone, or by an in-person hearing. If the value
							of your claim exceeds USD $10,000, the right to a hearing will be
							determined by the AAA Rules. Regardless of the manner in which the
							arbitration is conducted, the arbitrator shall issue a reasoned
							written decision sufficient to explain the essential findings and
							conclusions on which the award is based. All issues are for the
							arbitrator to decide, except that issues relating to the scope and
							enforceability of this arbitration provision or the arbitrability
							of disputes are for the court to decide. The arbitrator may
							consider, but is not bound by, rulings in other arbitrations
							between Bridgerock and Users. The arbitrator can award the same
							individualized damages and relief that a court can award.
							Judgement on the award may be entered by any court having
							jurisdiction.
						</p>

						<p>
							(c) Costs of Arbitration. The AAA’s fee schedule is subject to
							change and may be found in the AAA Rules (available online at
							www.adr.org or by calling the AAA at 1-800-778-7879). Bridgerock
							will pay all AAA filing, administrative and arbitrator fees for
							any arbitration that Bridgerock commences. If you provided
							Bridgerock with 60 days’ notice of your intent to arbitrate before
							commencing arbitration and the value of your claim is USD $75,000
							or less, Bridgerock will pay your share of any such AAA fees. If
							the value of your claim exceeds USD $75,000, the allocation of AAA
							fees will be governed by the AAA Rules (unless the law of your
							state requires Bridgerock to pay all such fees). If, however, the
							arbitrator finds that either the substance of your claim or the
							relief sought is frivolous or brought for an improper purpose (as
							measured by the standards in Federal Rule of Civil Procedure
							11(b)), then the payment of all AAA fees shall be governed by the
							AAA Rules. In such cases, the arbitrator may direct you to
							reimburse Bridgerock for amounts that Bridgerock paid on your
							behalf.
						</p>
						<p>
							(d) No Class Arbitration. The arbitrator may award declaratory or
							injunctive relief only in favor of the individual party seeking
							relief and only to the extent necessary to provide relief
							warranted by that party’s individual claim. TO THE FULLEST EXTENT
							PERMITTED BY APPLICABLE LAW, YOU AND BRIDGEROCK AGREE THAT EACH
							MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
							CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
							GROUP LITIGATION OR PRIVATE ATTORNEY GENERAL PROCEEDING. Further,
							unless all affected parties agree otherwise, the arbitrator may
							not consolidate more than one person’s claims, and may not
							otherwise preside over any form of a representative or group
							proceeding. If a court decides that applicable law precludes
							enforcement of any of this subsection’s limitations as to a
							particular claim for relief, then that claim (and only that claim)
							must be severed from the arbitration and may be brought in court.
						</p>
						<p>
							(e) 30-Day Opt-Out Period. If you do not wish to be bound by the
							arbitration and class-action waiver provisions in this Section 8,
							you must notify Bridgerock in writing within 30 calendar days of
							the date that you first accept these Terms (unless a longer period
							is required by applicable law). Your written notification must be
							sent to Bridgerock at the following address: 1501 Broadway, 17th
							Floor, New York, NY 10036. Subject to Section 8(g) below, if you
							do not notify Bridgerock in accordance with this Section 8(e), you
							agree to be bound by the arbitration and group litigation waiver
							provisions of these Terms, including such provisions in any Terms
							revised after the date of your first acceptance.
						</p>
						<p>
							Such notification must include: (a) your name, (b) your email
							address, (c) your mailing address and (d) a statement that you do
							not wish to resolve disputes with Bridgerock through arbitration.
							This notification affects these Terms only; if you previously
							entered into other arbitration agreements with Bridgerock or enter
							into other such agreements in the future, your notification that
							you are opting out of the arbitration provision in these Terms
							shall not affect the other arbitration agreements between you and
							Bridgerock.
						</p>
						<p>
							(f) Future Changes to Arbitration Provision. If Bridgerock makes
							any changes to the Dispute Resolution and Arbitration Section of
							these Terms (other than a change to the address at which
							Bridgerock will receive notices of dispute, opt-out notices or
							rejections of future changes to the Dispute Resolution and
							Arbitration Section), you may reject any such change by sending us
							written notice within 30 calendar days of the change to: 1501
							Broadway, 17th Floor, New York, NY 10036. It is not necessary to
							send us a rejection of a future change to the Dispute Resolution
							and Arbitration Section of these Terms if you had properly opted
							out of the arbitration and group litigation waiver provisions in
							this Section 8 within the first 30 calendar days after you first
							accepted these Terms. If you have not properly opted out of the
							arbitration and group litigation waiver provisions in this Section
							8, by rejecting a future change, you are agreeing that you will
							arbitrate any dispute between us in accordance with the language
							of this arbitration provision, as modified by any changes you did
							not reject.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>8. General</Heading3>
						<p>
							(a) Changes to These Terms. Bridgerock reserves the right to make
							changes to these Terms, and Bridgerock will post notice of changes
							to any one or more of the following: this page or a Site. If we
							make any substantial changes, we may notify you by sending you an
							e-mail to the last e-mail address you provided to us (if any),
							and/or by prominently posting notice of the changes on our
							Services. If you have provided us with an e-mail address, you are
							responsible for ensuring that it is your most current e-mail
							address. In the event that the last e-mail address that you have
							provided us is not valid, or for any reason is not capable of
							delivering to you the notice described above, our dispatch of the
							e-mail containing such notice will nonetheless constitute
							effective notice of the changes described in the notice. Any
							changes to these Terms will be effective upon the earlier of seven
							(7) calendar days following our dispatch of an e-mail notice to
							you (if applicable) or seven (7) calendar days following our
							posting of notice of the changes on the Services. These changes
							will be effective immediately for new Users of the Services.
							Continued use of the Services following notice of such changes
							shall indicate your acknowledgement of such changes and agreement
							to be bound by the terms and conditions of such changes. IF YOU DO
							NOT AGREE WITH ANY OF THE CHANGES TO ANY OF THE TERMS, YOU SHOULD
							CEASE ACCESSING OR USING THE SERVICES.
						</p>
						<p>
							(b) Governing Law; Jurisdiction and Venue. To the extent that the
							Agreement allows you or Bridgerock to initiate litigation in a
							court, other than for small claims court actions, you agree that
							this Agreement and all matters relating to your access to, or use
							of, the Services will be governed by the laws of the State of New
							York, USA, without giving effect to any conflict of laws
							principles that may provide the application of the law of another
							jurisdiction. You agree and hereby submit to the exclusive
							personal jurisdiction and venue of the state and federal courts
							located in the State of New York, USA, for any dispute arising out
							of or relating to this Agreement and the parties waive any
							objection based on venue or inconvenient forum.
						</p>
						<p>
							(c) Protection of Confidentiality and Intellectual Property
							Rights. Notwithstanding the foregoing, Bridgerock may seek
							injunctive or other equitable relief to protect its confidential
							information and intellectual property rights or to prevent loss of
							data or damage to its servers in any court of competent
							jurisdiction.
						</p>
						<p>
							(d) Entire Agreement/Severability. These Terms constitute the
							entire agreement between you and Bridgerock regarding the use of
							and access to the Services. Any failure by Bridgerock to exercise
							or enforce any right or provision of these Terms shall not operate
							as a waiver of such right or provision. If any provision of these
							Terms is, for any reason, held to be invalid or unenforceable, the
							other provisions of these Terms will be unimpaired and the invalid
							or unenforceable provision will be deemed modified so that it is
							valid and enforceable to the maximum extent permitted by law.
						</p>
						<p>
							(e) Assignment. These Terms, and any associated rights or
							obligations, may not be assigned or otherwise transferred by you
							without Bridgerock’s prior written consent. These Terms may be
							assigned by Bridgerock without restriction. These Terms are
							binding upon any permitted assignee.
						</p>
						<p>
							(f) Electronic Communications. By using the Services, you (a)
							consent to receive communications from us in an electronic form,
							and (b) agree that all terms and conditions, agreements, notices,
							disclosures, and other communications that we provide to you
							electronically satisfy any legal requirement that such
							communications would satisfy if it were in a hardcopy writing. The
							foregoing does not affect your non-waivable rights.
						</p>
						<p>
							(g) Notifications. Bridgerock may provide notifications to you as
							required by law, or for marketing or other purposes, via (at its
							option) e-mail, hard copy or posting of such notice on
							reimbucks.com. Bridgerock is not responsible for any automatic
							filtering that you or your network provider may apply to email
							notifications.
						</p>
						<p>
							(h) Marks. All trademarks, logos and service marks (“Marks”)
							displayed on the Services are the property of Bridgerock or of
							their respective holders. You are not permitted to use any of the
							Marks without the applicable prior written consent of Bridgerock
							or such respective holders. Bridgerock reserves the right to alter
							services offerings, specifications and pricing at any time,
							without notice, and is not responsible for typographical or
							graphical errors that may appear in these Terms or in related
							guidelines, policies, or rules.
						</p>
						<p>
							(i) Section Titles. The section titles in this Agreement are for
							convenience only and have no legal or contractual effect. The word
							“including” means “including without limitation”.
						</p>
						<p>
							(j) Relationship of Parties. Your relationship to Bridgerock is
							that of a party to an independent contractor, and neither party is
							an agent or partner of the other.
						</p>
						<p>
							(k) Local Laws. We make no representation that any materials on
							the Services are appropriate or available for use in jurisdictions
							that are outside the United States. Access to the Services from
							jurisdictions where such access is illegal is prohibited. If you
							choose to access the Services from other jurisdictions, you do so
							at your own initiative and are responsible for compliance with
							applicable local laws.
						</p>
						<p>
							(l) Force Majeure. Neither party will be responsible to the other
							for any delay in performing under this Agreement which results
							from civil disturbance, undeclared or declared war or other
							hostilities, acts of terrorism, acts of anarchy, labor strikes or
							interruptions, earthquakes, or other acts of Nature or acts of
							God, governmental orders, diseases, pandemics, or any cause beyond
							the reasonable control of such party.
						</p>
						<p>
							(m) Contact Information. If you have additional questions or
							comments of any kind, please let us know by sending your comments
							or requests to us at:
						</p>
						<div className="flex flex-col">
							<span>BRIDGEROCK TECHNOLOGIES LLC</span>
							<span>1501 Broadway, 17th Floor</span>
							<span>New York, NY 10036</span>
							<span>United States</span>
							<span>Phone: (646) 457-4775</span>
							<span>Fax: (646) 499-3953</span>
							<a href="mailto:info@bridgerock.io" className="text-brand">
								info@bridgerock.io
							</a>
						</div>
					</RelatedContents>
				</ContentContainer>
			</Main>
			{!isFromApp && <Footer />}
		</>
	);
};
