import { useRef, forwardRef } from 'react';

export const Input = forwardRef(function Input(props, ref) {
	const {
		button,
		className,
		disabled,
		errorClassName,
		errorMessage,
		inputClassName,
		inputGroupClassName,
		label,
		labelClassName,
		labelOnTop = true,
		name,
		placeholder,
		readOnly,
		type,
		...rest
	} = props;
	const inputRef = useRef();

	return (
		<>
			{!labelOnTop ? (
				<div className={`${inputGroupClassName ? inputGroupClassName : ''}`}>
					<div className="flex flex-col gap-[6px]">
						<div
							className={`px-[12px] py-[10px] border-2 rounded-[6px] bg-[#f5f5f5] flex items-center gap-[12px]${
								!readOnly ? ' focus-within:border-brand' : ''
							}${errorMessage ? ' border-[#DD5250]' : '  border-transparent'} ${
								className ? className : ''
							}`}>
							{label && (
								<label
									htmlFor={name}
									className={`text-[1.4rem] font-semibold ${
										labelClassName ? labelClassName : ''
									}`}>
									{label}
								</label>
							)}
							<input
								ref={inputRef}
								name={name}
								type={type || 'text'}
								className={`text-right ml-auto bg-transparent w-4/6 text-[1.5rem] disabled:cursor-not-allowed disabled:opacity-50 ${
									inputClassName ? inputClassName : ''
								}`}
								placeholder={placeholder}
								readOnly={readOnly}
								disabled={disabled}
								{...rest}
							/>
							{button && button}
						</div>
						{errorMessage && (
							<small
								className={`block text-[#D62C29] font-[600] text-[1.1rem] ${
									errorClassName ? errorClassName : ''
								}`}>
								{errorMessage}
							</small>
						)}
					</div>
				</div>
			) : (
				<div className={`${inputGroupClassName ? inputGroupClassName : ''}`}>
					{label && (
						<label
							htmlFor={name}
							className={`block text-[1.8rem] font-[600] mb-[4px] ${
								labelClassName ? labelClassName : ''
							}`}>
							{label}
						</label>
					)}
					<div className="flex gap-[8px] items-center">
						<input
							ref={inputRef}
							name={name}
							type={type || 'text'}
							className={`flex-1 block w-full border-2 px-[6px] py-[10px] focus:border-action-primary disabled:cursor-not-allowed bg-[rgba(85,96,112,0.08)] h-[40px] text-[1.6rem] rounded-[8px]  ${
								errorMessage
									? ' border-error focus:border-error'
									: ' border-transparent focus:border-action-primary'
							}${className ? ` ${className}` : ''}${
								inputClassName ? ` ${inputClassName}` : ''
							}`}
							placeholder={placeholder}
							readOnly={readOnly}
							disabled={disabled}
							{...rest}
						/>
						{button && button}
					</div>
					{errorMessage && (
						<small
							className={`block mt-[6px] text-error font-[600] text-[1.1rem] ${
								errorClassName ? errorClassName : ''
							}`}>
							{errorMessage}
						</small>
					)}
				</div>
			)}
		</>
	);
});
