import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import { LandingPage, TermsOfService, PrivacyPolicy, ContactUs } from 'pages';
import { TERMS_LINK, PRIVACY_LINK, CONTACT_LINK } from 'data';

function App() {
	const { pathname } = useLocation();
	useEffect(() => {
		if (!['/contact', '/'].includes(pathname)) window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<Routes>
			<Route
				path="/"
				element={
					<>
						<LandingPage />
						<Outlet />
					</>
				}>
				<Route path={CONTACT_LINK} element={<ContactUs />} />
			</Route>
			<Route path={TERMS_LINK} element={<TermsOfService />} />
			<Route path={PRIVACY_LINK} element={<PrivacyPolicy />} />
			<Route path="*" element={<LandingPage />} />
		</Routes>
	);
}

export default App;
