import { Blur, Title } from 'components/common';
import Competitors from 'assets/images/imageComparisonCompetitors.svg';
import Ours from 'assets/images/imageComparisonOurs.svg';
import Logo from 'assets/images/imageLogo.svg';

export const Comparison = () => {
	return (
		<div className="bg-background overflow-hidden">
			<div className="py-[50px] md:py-[100px] w-[90%] xl:w-max-laptop 2xl:w-max-monitor mx-auto relative">
				<Blur
					color="blue"
					className="hidden md:block w-[600px] h-[600px] -top-[200px] -left-[400px] lg:-left-[300px]"
				/>
				<Blur
					color="blue"
					className="w-[800px] h-[800px] -bottom-[400px] -right-[600px] lg:-right-[300px] rounded-none"
				/>
				<Blur
					color="blue"
					className="w-[600px] h-[600px] top-[200px] md:-top-[100px] -right-[400px] lg:-right-[300px]"
				/>
				<Title className="mb-[50px] md:mb-[150px]">
					How much can you save?
				</Title>
				<div className="flex flex-col gap-[60px] md:gap-[20px] md:flex-row justify-between items-center mx-auto max-w-[720px] relative">
					<div className="flex flex-col items-center gap-[29px]">
						<img
							src={Competitors}
							alt="Manual Expense Report"
							className="max-w-[222px]"
						/>
						<p className="text-[2.4rem] max-w-[185px] text-center leading-[29px]">
							Time it takes to manually create a typical expense report
						</p>
					</div>
					<div className="flex flex-col items-center">
						<img
							src={Ours}
							alt="Reimbucks Automated Expense Report"
							className="max-w-[222px]"
						/>
						<p className="text-[2.4rem] max-w-[196px] text-center leading-[29px] mt-[29px] mb-[5px]">
							Time it takes to create an expense report with{' '}
						</p>
						<img src={Logo} alt="Reimbucks" className="max-w-[148px]" />
					</div>
				</div>
			</div>
		</div>
	);
};
