import { NavLink } from 'react-router-dom';

import { TERMS_LINK, PRIVACY_LINK } from 'data';

import { ConsistentSpacing } from '.';

export const Footer = () => {
	return (
		<footer className="bg-[#f2f2f4] py-[16px]">
			<ConsistentSpacing className="flex flex-col sm:flex-row items-center gap-[16px] text-[#858595] text-[1.4rem]">
				<span className="text-center sm:text-left">
					&copy; {new Date().getFullYear()}, BridgeRock Technologies LLC. All
					Rights Reserved.
				</span>
				<div className="sm:ml-auto flex flex-wrap gap-[16px]">
					<NavLink to={TERMS_LINK}>Terms of Services</NavLink>
					<span className="text-[#C4C4C4]">|</span>
					<NavLink to={PRIVACY_LINK}>Privacy Policy</NavLink>
				</div>
			</ConsistentSpacing>
		</footer>
	);
};
