export const TextArea = ({
	label,
	containerClassName,
	labelClassName,
	textareaClassName,
	...rest
}) => {
	return (
		<div className={`flex flex-col ${containerClassName}`}>
			<label
				className={`block text-[1.8rem] font-[600] mb-[4px] ${labelClassName}`}>
				{label}
			</label>
			<textarea
				className={`flex-1 block w-full border-2 border-transparent px-[6px] py-[10px] focus:border-action-primary disabled:cursor-not-allowed bg-[rgba(85,96,112,0.08)] 
        text-[1.6rem] rounded-[8px] resize-none ${textareaClassName}`}
				{...rest}
			/>
		</div>
	);
};
