import {
	ContentContainer,
	Footer,
	Header,
	Heading3,
	Heading4,
	Headline,
	Main,
	RelatedContents,
	SquareListStyle,
	Underline,
} from 'components/termsAndPrivacy';
import useCheckSource from 'hooks/useCheckSource';

const supportMail = (
	<a href="mailto:support@reimbucks.com">
		<span className="text-brand">support@reimbucks.com</span>
	</a>
);

export const PrivacyPolicy = () => {
	const { isFromApp } = useCheckSource();
	return (
		<>
			<Header />

			<Main>
				<Headline title="PRIVACY POLICY" updateOn="Last updated Sep 1, 2022" />

				<ContentContainer>
					<p>
						This Privacy Policy describes the information Bridgerock
						Technologies LLC ("<strong>Bridgerock</strong>", "
						<strong>we</strong>", "<strong>our</strong>", or "
						<strong>us</strong>") collects when you use the Reimbucks website
						and/or mobile application (collectively, the "
						<strong>Services</strong>"), how we use this information, with whom
						we share it, and the choices you have in connection with this.
					</p>

					<RelatedContents>
						<Heading3>INFORMATION WE COLLECT AND USE</Heading3>
						<p>
							Through your use of the Services, Bridgerock may collect personal
							information, which is information that identifies you or relates
							to you as an identifiable individual. Bridgerock will collect
							personal information from you directly and automatically as
							outlined below.
						</p>
						<RelatedContents className="ml-[16px] mt-[20px]">
							<Heading4>
								A. Information Collected From You When You Use the Website
							</Heading4>
							<p>
								As you use the Services, we collect personal information from
								you when you:
							</p>
							<SquareListStyle>
								<li>
									<Underline>
										<strong>Create an account:</strong>
									</Underline>{' '}
									When you create a Reimbucks account, we will collect your
									personal identifiers (name, business name, email address, and
									selected username and password). We use this personal
									information to create your account and to provide you with
									access to the Services. Once we create your account, you will
									be able to upload copies of your receipts, approve or reject
									reimbursement requests, and organize your expenses.
								</li>
								<li>
									<Underline>
										<strong>Make a payment:</strong>
									</Underline>{' '}
									To purchase a subscription to the Service, we collect your
									name, physical address, and payment card information (card
									number, CVV, expiration date). We use this personal
									information to process your payment. Please note our payment
									processor collects and processes this information directly on
									our behalf and Bridgerock only maintains historical payment
									information.
								</li>
							</SquareListStyle>
						</RelatedContents>

						<RelatedContents className="ml-[16px] mt-[20px]">
							<Heading4>
								B. Information Collected From You When You Use the Mobile
								Application
							</Heading4>
							<p>
								As you use the Services, we collect personal information from
								you when you:
							</p>
							<SquareListStyle>
								<li>
									<Underline>
										<strong>Submit a reimbursement request:</strong>
									</Underline>{' '}
									When you submit an image or scanned document of a receipt for
									reimbursement, we will collect any information that is
									included in the image or scanned document, which, depending on
									the receipt, may include your name, email address, telephone
									number, and the last four digits of your payment card number.
									We will use this information to facilitate the reimbursement
									request.
								</li>
							</SquareListStyle>
							<p>
								Please note Bridgerock may use the personal information
								identified in this section to comply with the law and for other
								limited circumstances as described in{' '}
								<a href="#how-we-share">
									<strong>HOW WE SHARE YOUR INFORMATION</strong>
								</a>
								.
							</p>
						</RelatedContents>

						<RelatedContents className="ml-[16px] mt-[20px]">
							<Heading4>C. Information Collected Automatically</Heading4>
							<p>
								In addition to the personal information you provide, we also
								collect information automatically via cookies as you use our
								Services. This includes your:
							</p>
							<SquareListStyle>
								<li>
									<Underline>
										<strong>Usage information:</strong>
									</Underline>{' '}
									We use strictly necessary and functional cookies to collect
									information about your interaction with our Services, such as
									the pages you visit, the frequency of access, how much time
									you spend on each page, what you click on, and referring
									website address. We use this personal information to: (i)
									enhance user experience; (ii) prevent fraudulent use of the
									Services; and (iii) diagnose and repair errors.
								</li>
								<li>
									<Underline>
										<strong>Device information:</strong>
									</Underline>{' '}
									We use strictly necessary and functional cookies to collect
									certain information about the device you use to access our
									Services, such as browser type, browser language, hardware
									model, operating system, and your preferences. We use this
									personal information to: (i) enhance user experience; (ii)
									prevent fraudulent use of the Services; (iii) diagnose and
									repair errors; (iv) remember your preferences; and (v) provide
									enhanced functionality.
								</li>
								<li>
									<Underline>
										<strong>Location information:</strong>
									</Underline>{' '}
									We use strictly necessary and functional cookies to collect
									information about your location, which may be determined
									through your IP address. We use this personal information to:
									(i) enhance user experience; (ii) prevent fraudulent use of
									the Services; (iii) diagnose and repair errors; (iv) remember
									your preferences; and (v) provide enhanced functionality.
								</li>
							</SquareListStyle>
							<p>
								To conduct analytics, we use Google Analytics. In doing so,
								Google Analytics may set cookies on your device, or read cookies
								that are already there. Google Analytics may also receive
								information about you from applications you have downloaded that
								partner with Google. We do not combine the information collected
								through the use of Google Analytics with personally identifiable
								information. Google may use and share the information collected
								through Google Analytics in accordance with its{' '}
								<a
									href="https://policies.google.com/technologies/partner-sites"
									target="_blank"
									rel="noreferrer"
									className="text-brand">
									Terms of Use and Privacy Policy
								</a>
								. To opt-out, you can download the Google Analytics{' '}
								<a
									href="https://tools.google.com/dlpage/gaoptout"
									target="_blank"
									rel="noreferrer"
									className="text-brand">
									opt-out browser add-on
								</a>
								.
							</p>
							<p>
								In general, to disable cookies and limit the collection and use
								of information through them, you can set your browser or mobile
								device settings to refuse cookies or indicate when a cookie is
								being sent.
							</p>
						</RelatedContents>
					</RelatedContents>

					<RelatedContents>
						<Heading3 id="how-we-share" className="scroll-mt-[130px]">
							HOW WE SHARE YOUR INFORMATION
						</Heading3>
						<p>
							Bridgerock may share your personal information in the following
							instances:
						</p>
						<SquareListStyle>
							<li>
								<Underline>
									<strong>Within Bridgerock:</strong>
								</Underline>{' '}
								Where necessary, we may share your personal information within
								Bridgerock in order to efficiently carry out our business and to
								the extent permitted by law.
							</li>
							<li>
								<Underline>
									<strong>With service providers:</strong>
								</Underline>{' '}
								We may share your personal information with our service
								providers that assist us in providing the Services, such as our
								IT support, payment processor, and our cloud provider for
								storage, management, and authentication.
							</li>
							<li>
								<Underline>
									<strong>In the event of a corporate reorganization:</strong>
								</Underline>{' '}
								In the event that we enter into, or intend to enter into, a
								transaction that alters the structure of our organization, such
								as a reorganization, merger, acquisition, sale, joint venture,
								assignment, consolidation, transfer, change of control, or other
								disposition of all or any portion of our assets, we would share
								your personal information with third parties, including the
								buyer or target (and their agents and advisors) for the purpose
								of facilitating and completing the transaction. We would also
								share your personal information with third parties if we undergo
								bankruptcy or liquidation, in the course of such proceedings.
							</li>
							<li>
								<Underline>
									<strong>For legal purposes:</strong>
								</Underline>{' '}
								We will share your personal information where we are legally
								required to do so, such as in response to court orders, law
								enforcement or legal process, including for national security
								purposes; to establish, protect, or exercise our legal rights,
								as required to enforce our terms of use or other contracts; to
								defend against legal claims or demands; to detect, investigate,
								prevent, or take action against illegal activities, fraud, or
								situations involving potential threats to the rights, property,
								or personal safety of any person; or to comply with the
								requirements of any applicable law.
							</li>
							<li>
								<Underline>
									<strong>With Stripe:</strong>
								</Underline>{' '}
								We use Stripe, Inc., as our third-party payment processor, for
								all fees related to our Services. Therefore, Stripe will receive
								your financial information in order to facilitate payment.
								Stripe may share the information collected through Stripe in
								accordance with its{' '}
								<a
									href="https://stripe.com/privacy"
									target="_blank"
									rel="noreferrer"
									className="text-brand">
									Privacy Policy
								</a>
								.
							</li>
						</SquareListStyle>
					</RelatedContents>

					<RelatedContents>
						<Heading3>RIGHTS OF NEVADA RESIDENTS</Heading3>
						<p>
							If you are a consumer in the State of Nevada, you may request to
							opt-out of the current or future sale of your personal
							information. We do not currently sell any of your personal
							information under Nevada law, nor do we plan to do so in the
							future. However, you can submit a request to opt-out of future
							sales by contacting us at {supportMail}. Please include “Opt-Out
							Request Under Nevada Law” in the subject line of your message.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>CORRECT OR VIEW YOUR PERSONAL INFORMATION</Heading3>
						<p>
							You can access your Reimbuck’s account to correct or view certain
							personal information of yours in our possession and which is
							associated with your account.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>DELETE YOUR ACCOUNT</Heading3>
						<p>
							You can delete your Reimbuck’s account at any time from the
							website under the Settings page. You can stop all further
							collection of your personal information by the Reimbuck’s mobile
							application by uninstalling the Reimbuck’s mobile application.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>PUSH NOTIFICATIONS</Heading3>
						<p>
							If you have enabled push notifications on the Reimbucks mobile
							application, you may disable these at any time by updating your
							mobile device settings.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>DO NOT TRACK</Heading3>
						<p>We do not support Do Not Track (DNT).</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>INFORMATION SECURITY</Heading3>
						<p>
							We implement and maintain reasonable security measures, in
							compliance with ISO/IEC 27001:2013, SOC 2, and PCI-DSS to protect
							the personal information we collect and maintain. However, no
							security measure or modality of data transmission over the
							Internet is 100% secure and we are unable to guarantee the
							absolute security of the information we have collected from you.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>AGE RESTRICTION</Heading3>
						<p>
							The Services are not intended for individuals under the age of
							eighteen (18).
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>CHANGES TO THIS PRIVACY POLICY</Heading3>
						<p>
							We may change this Privacy Policy from time to time. We will post
							the changes to this page and will indicate the date the changes go
							into effect. We encourage you to review our Privacy Policy to stay
							informed. If we make changes that materially affect your privacy
							rights we will notify you with a prominent post on our Services
							and obtain your consent, if required.
						</p>
					</RelatedContents>

					<RelatedContents>
						<Heading3>CONTACT</Heading3>
						<p>
							If you have any questions about this Privacy Policy, please
							contact us at {supportMail}
						</p>
					</RelatedContents>
				</ContentContainer>
			</Main>
			{!isFromApp && <Footer />}
		</>
	);
};
