import {
	Hero,
	WhyReimbucks,
	Comparison,
	Testimonials,
	Features,
	Pricing,
	Banner,
	Footer,
} from 'components/landingPage';

export const LandingPage = () => {
	return (
		<>
			<Hero />
			<div id="why-reimbucks">
				<WhyReimbucks />
			</div>
			<div id="product">
				<Features />
				<Comparison />
				<Testimonials />
			</div>
			<div id="pricing">
				<Pricing />
				<Banner />
			</div>
			<Footer />
		</>
	);
};
