export const pricing = {
	title: 'Professional',
	cost: {
		monthly: '$5.00',
		yearly: '$4.00',
	},
	frequency: '/month',
	unit: 'per user',
	description:
		'Great for small businesses, project teams, or startups with 1-25 employees. No user minimum.',
	featureList: [
		'Smart receipt scanning',
		'Unlimited expense tracking',
		'Instant reimbursements',
		'Single-tier approval process',
		'Intuitive overview for a full view of spending',
		'Custom categorization and filtering',
		'Multi-currency support and automatic conversion to USD',
		'Simple setup and user creation',
		'Automated expense reports',
		'Unparalleled support',
	],
};
