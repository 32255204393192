import { Title, Blur } from 'components/common';
import Testimonial from 'assets/images/imageTestimonial.png';
import TestimonialMobile from 'assets/images/imageTestimonialMobile.png';
import TechCrunch from 'assets/images/imageTestimonialTechCrunch.svg';
import TestimonialPlaceholder from 'assets/images/imageTestimonialPlaceholder.png';

export const Testimonials = () => {
	return (
		<div className="bg-background overflow-hidden">
			<div className="pt-[80px] pb-[40px] md:pb-0 w-full xl:w-max-laptop 2xl:w-max-monitor mx-auto relative">
				<Blur className="w-[900px] md:w-[650px] h-[900px] md:h-[650px] top-[70px] -right-[300px] md:-top-[100px] md:-right-[150px]" />
				<Title className="mb-[60px]">Don’t take our word for it</Title>
				<div className="flex flex-col md:flex-row items-center relative">
					<img
						className="hidden md:block h-[400px] lg:h-auto"
						src={Testimonial}
						alt="Marc Balke"
					/>
					<img
						className="md:hidden w-[90%] shadow-xl"
						src={TestimonialMobile}
						alt="Marc Balke"
					/>
					<div className="w-[85%] md:max-w-[280px] lg:max-w-[350px] xl:max-w-[430px] 2xl:max-w-[490px] mt-[50px] lg:mt-0">
						<p className="font-bold text-[2.8rem]">
							“Tracking organizational expenses used to mean checking our
							monthly credit card statements. Now we use Reimbucks.”
						</p>
						<div className="flex flex-col lg:flex-row lg:items-center justify-between mt-[35px]">
							<div>
								<h3 className="font-bold text-[2.8rem]">Marc Balcke</h3>
								<h4 className="text-[2rem] font-normal">
									Corporate Controller
								</h4>
							</div>
							<img
								className="w-[130px] lg:w-auto mt-[15px] lg:mt-0"
								src={TechCrunch}
								alt="TechCrunch"
							/>
						</div>
					</div>
				</div>
				<img
					className="hidden md:block absolute bottom-0 -right-[250px] h-[380px] lg:h-auto"
					src={TestimonialPlaceholder}
					alt="Placeholder"
				/>
			</div>
		</div>
	);
};
