export const Modal = ({
	backdropClassName,
	containerClassName,
	closeOnBackdropClick = true,
	closeModal,
	isOpen = false,
	children,
}) => {
	if (!isOpen) return null;
	return (
		<div
			className={`fixed inset-0 flex justify-center z-50 bg-backdrop ${backdropClassName}`}
			onClick={closeOnBackdropClick ? closeModal : null}>
			<div
				onClick={(e) => e.stopPropagation()}
				className={`bg-white min-w-[min(450px,90vw)] min-h-[min(200px,80vh)] m-auto rounded-[10px] shadow-[0px_16px_32px_rgba(0,0,0,0.16),0px_8px_16px_rgba(0,0,0,0.08),0px_0px_4px_rgba(0,0,0,0.04)] 
        ${containerClassName}`}>
				{children}
			</div>
		</div>
	);
};
