export const Button = ({
	children,
	variant = 'primary',
	className,
	link,
	...others
}) => {
	const colors =
		variant === 'primary'
			? 'bg-action-primary text-white'
			: 'bg-action-secondary text-black';
	return link ? (
		<a
			href={link}
			className={`${colors} font-medium text-[1.8rem] px-[15px] h-[40px] rounded-[8px] flex justify-center items-center ${
				className ?? className
			}`}
			{...others}>
			{children}
		</a>
	) : (
		<button
			className={`${colors} font-medium text-[1.8rem] px-[15px] h-[40px] rounded-[8px] disabled:opacity-75 disabled:cursor-not-allowed ${
				className ?? className
			}`}
			{...others}>
			{children}
		</button>
	);
};
