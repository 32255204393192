import { Link } from 'react-scroll';

import { Button, Blur } from 'components/common';
import HeroImage from 'assets/images/imageHero.png';
import PressBg from 'assets/images/imageHeroPressBackground.svg';
import PressBgMobile from 'assets/images/imageHeroPressBackgroundMobile.svg';
import BackgroundNoise from 'assets/images/imageLandingBackgroundNoise.png';
import { press, SIGNUP_LINK } from 'data';

import { Header } from '.';

export const Hero = () => {
	return (
		<div className="bg-background overflow-hidden">
			<img
				src={BackgroundNoise}
				alt="Noise"
				className="fixed w-screen h-screen"
			/>
			<Header />
			<div className="relative mx-auto mt-[40px] md:mt-[80px] pb-[40px] md:pb-[80px] w-[90%] xl:w-max-laptop 2xl:w-max-monitor">
				<Blur className="-top-[300px] -right-[500px] lg:-right-[150px] w-[500px] h-[600px]" />
				<img
					src={HeroImage}
					alt="Reimbucks - The Simplest Expense Management for Small Businesses"
					className="hidden md:block w-[100%] lg:w-[85%] xl:w-[80%] absolute -top-[25px] lg:-top-[50px] xl:-top-[70px] -right-[35%] lg:-right-[20%] xl:left-[30%]"
				/>
				<Blur
					color="blue"
					className="top-[150px] -left-[600px] md:-left-[200px] w-[1000px] h-[1000px]"
				/>
				<div className="relative">
					<h1 className="max-w-[640px] mb-[20px] md:mb-[30px] text-[3.6rem] md:text-[6.4rem] font-extrabold font-calibre-medium">
						Expense it in a snap!
					</h1>
					<p className="max-w-[530px] text-[1.8rem] md:text-[2.4rem] leading-[1.2]">
						Reimbucks is your all-in-one platform for reporting, approving, and
						managing expenses.
					</p>
					<div className="mt-[30px] md:mt-[60px] flex flex-col gap-6 md:gap-4 md:flex-row">
						<Button link={SIGNUP_LINK} className="h-[48px] px-[30px]">
							Start free trial
						</Button>
						<Link to="why-reimbucks" smooth={true} offset={-80} duration={500}>
							<Button
								variant="secondary"
								className="h-[48px] w-full px-[30px] font-semibold">
								Learn more
							</Button>
						</Link>
					</div>
				</div>
				<div className="bg-white mt-[40px] md:mt-[80px] xl:mt-[130px] rounded-[16px] py-[35px] md:px-[40px] md:py-[50px] lg:px-[80px] lg:py-[70px] shadow-xl relative">
					<img
						className="h-full w-[85%] hidden md:block absolute top-0 right-0"
						src={PressBg}
						alt="Shape"
					/>
					<img
						className="md:hidden absolute  right-0"
						src={PressBgMobile}
						alt="shape"
					/>
					<h3 className="text-center md:text-left text-[2.8rem] font-extrabold font-calibre-medium mb-[35px]">
						You may have seen us on...
					</h3>
					<div className="flex flex-col md:flex-row gap-[40px] md:gap-[5px] items-center justify-between relative">
						{press.map(({ imgSrc, alt }) => (
							<img key={alt} src={imgSrc} alt={alt} className="md:w-[10%]" />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
